<template>
  <v-menu
    :disabled="!item.items || item.items.length === 0"
    :offset="8"
  >
    <template #activator="{ props }">
      <div
        v-test-id="`${item.text}-item`"
        class="deck-dock-item"
        :class="{
          'd-none d-md-flex': item.hideOnMobile,
        }"
        :style="itemStyle"
      >
        <v-badge
          class="deck-dock-item__badge"
          :content="item.badge"
          :model-value="displayBadge"
          :offset-x="16"
          :offset-y="16"
          :color="item.badgeColor || 'primary'"
          floating
        >
          <deck-button
            :icon="item.icon"
            is-ready
            :size="size"
            :color="isActive || isHovering ? 'primary' : 'control'"
            :kind="isActive ? 'secondary' : 'ghost'"
            icon-kind="solid"
            :to="item.to"
            :text="item.text"
            :tooltip-props="{
              position: tooltipPosition,
              text: item.text,
            }"
            class="deck-dock-item__button"
            :class="item.classSelector"
            :disabled="!item.to && !item.onClick && (!item.items || item.items.length === 0)"
            v-bind="props"
            @click="item.onClick"
            @mouseover="isHovering = true"
            @mouseleave="isHovering = false"
          >
            <v-img
              v-if="item.imageUrl"
              :alt="item.text"
              :src="item.imageUrl"
              class="deck-dock-item__image"
            />
          </deck-button>
        </v-badge>
      </div>
    </template>

    <v-list density="compact">
      <v-list-item
        v-for="subItem in item.items"
        :key="subItem.text"
        density="compact"
        :to="subItem.to"
        slim
        @click="subItem.onClick"
      >
        <template #prepend>
          <deck-icon
            :name="subItem.icon"
            size="small"
            kind="regular"
            start
          />
        </template>

        <v-list-item-title>
          {{ subItem.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
const TOOLTIP_POSITION_AGAINST_DOCK = {
  left: 'right',
  right: 'left',
  top: 'bottom',
  bottom: 'top',
};

export default {
  name: 'DeckDockItem',
  props: {
    /**
     * The item to display in the dock.
     * @type {{
     *  active: boolean?,
     *  icon: string,
     *  text: string,
     *  badge: string? | number?,
     *  classSelector: string?,
     *  imageUrl: string?,
     *  to: string?,
     *  onClick: function?,
     *  items: Array<{
     *    icon: string,
     *    text: string,
     *    to: string?,
     *    onClick: function?
     *  }>
     * }}
     * @required
     */
    item: {
      type: Object,
      required: true,
    },
    /**
     * The position of the dock.
     * @type {'top' | 'left' | 'right' | 'bottom'}
     * @required
     */
    dockPosition: {
      type: String,
      required: true,
    },
    /**
     * The size of the dock.
     * @type {'small' | 'default' | 'large'}
     * @default 'default'
     */
    size: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  computed: {
    tooltipPosition() {
      return TOOLTIP_POSITION_AGAINST_DOCK[this.dockPosition];
    },
    itemStyle() {
      if (['left', 'right'].includes(this.dockPosition)) {
        return {
          transform: 'rotate(-90deg)',
        };
      }

      return {};
    },
    isActive() {
      return this.item.active || (Boolean(this.item.to) && this.$route.path === this.item.to);
    },
    displayBadge() {
      return Object.prototype.hasOwnProperty.call(this.item, 'badge');
    },
  },
};
</script>

<style lang="scss">
.deck-dock-item {
  position: relative; // used to position the active indicator

  .deck-button{
    height: var(--z-dock-item-height) !important;
    width: var(--z-dock-item-height) !important;
    border-radius: var(--z-dock-base-radius) !important;
  }
}

.deck-dock-item__badge {
  display: block; // the v-badge component has a inline display and it breaks the element height
}

.deck-dock-item__button {
  --deck-button-padding-inline: 0;
  border-radius: var(--z-dock-base-radius);

  min-width: unset; // override vuetify v-btn min-width
}

.deck-dock-item__image {
  border-radius: var(--z-dock-base-radius);
  height: var(--z-dock-item-height);
  object-fit: contain;
  width: var(--z-dock-item-height);
}
</style>
